import { segmentTrack } from 'lib/Segment';
import semver from 'semver';

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PHONE_REGEX_STRIP_CODE = /\(\s[2-9]\d{2}\s\)\s\d{3}\s-\s\d{4}/;
export const PHONE_REGEX =
  /^(\+1|1)?([\s]+)?([\.\-\(])?([\s]+)?\d{3}([\s]+)?([\.\-\)])?([\s]+)?\d{3}([\s]+)?([\.\-])?([\s]+)?\d{4}$/;
export const FORMATED_PHONE_REGEX = /\+1\s\(\s[2-9]\d{2}\s\)\s\d{3}\s-\s\d{4}/;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
export const VALID_CHARS_REGEX =
  /[^a-zA-Z0-9`~!@#$%^&*()-_=+{}|,.<>;:'"/?\[\]]+/g;
export const USERNAME_REGEX = /^\$[a-zA-Z0-9][a-zA-Z0-9-_]{2,27}$/;
export const UUID_REGEX =
  /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/;
export const URL_REGEX =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
export const URL_LOCALHOST =
  /((http([s]){0,1}:\/\/){0,1}(localhost|127.0.0.1){1}(([:]){0,1}[\0-9]{4}){0,1}\/{0,1}){1}/g

/**
 * @description Check if value is of type 'object'
 * @param val
 * @returns {boolean}
 */
export const isObj = (val: any): boolean =>
  typeof val === 'object' && !isArr(val) && !isNull(val);

/**
 * @description Check if value is of type 'null'
 * @param val
 * @returns {boolean}
 */
export const isNull = (val: any): boolean => val === null;

/**
 * @description Check if value is of type 'number'
 * @param val
 * @returns {boolean}
 */
export const isNum = (val: any): boolean =>
  typeof val === 'number' && !isNaN(val);

/**
 * @description Check if value is of type 'function'
 * @param val
 * @returns {boolean}
 */
export const isFunc = (val: any): boolean => typeof val === 'function';

/**
 * @description Check if value is of type 'array'
 * @param val
 * @returns {boolean}
 */
export const isArr = (val: any): boolean => Array.isArray(val);

/**
 * @description Check if value is of type 'string'
 * @param val
 * @returns {boolean}
 */
export const isStr = (val: any): boolean => typeof val === 'string';

/**
 * @description Check if value is of type 'undefined'
 * @param val
 * @returns {boolean}
 */
export const isUndef = (val: any): boolean => typeof val === 'undefined';

/**
 * @description Sort a given array
 * @param array
 * @param fields
 * @returns
 */
export const sortArray = (givenArray: any, comparisonField: string): any => {
  return givenArray.sort((orgNode, compNode) => {
    if (orgNode[comparisonField] < compNode[comparisonField]) {
      return -1;
    }
    if (orgNode[comparisonField] > compNode[comparisonField]) {
      return 1;
    }
    return 0;
  });
};

/**
 * @description compare 2 arrays
 * @params array1, array2
 * @returns {boolean}
 */
export const isArrayEqual = (ar1, ar2) =>
  JSON.stringify(ar1) === JSON.stringify(ar2);

export const isBlank = (val: any): boolean =>
  val === null || val === undefined || val.toString().trim() === '';

export const toDecimal = (amount: string | number | undefined) => {
  const amountInDecimals = amount
    ? parseFloat(amount.toString().replace(/[^\d.-]/g, ''))
    : 0;
  return isNaN(amountInDecimals) ? 0 : amountInDecimals;
};

export const cleanToken = (token: string) =>
  token !== '' && token !== null && token !== undefined
    ? token.replace(/\D/g, '')
    : '';

export const formatPhoneNumber = (
  phone: string | undefined | null | number,
  country_code: boolean = true
) => {
  const format = country_code ? '+1 ( $1 ) $2 - $3' : '( $1 ) $2 - $3'
  return phone !== '' && phone !== null && phone !== undefined
    ? cleanPhoneNumber(phone.toString()).replace(
      /^(\d\d\d)(\d{3})(\d{0,4}).*/,
      format
    )
    : '';
};

export const cleanPhoneNumber = (phone: any): string => {
  if (phone === undefined) return '';
  let newPhone = phone.includes('@') ? phone : phone.replace(/^(\+1)|\D/g, '');
  return (/[0-1]/.test(newPhone[0]) ? newPhone.slice(1) : newPhone).slice(
    0,
    10
  );
};

export const maskPhoneNumber = (phone: string | undefined | null | number) => {
  return phone !== '' && phone !== null && phone !== undefined
    ? '(***) *** **' + cleanPhoneNumber(phone.toString()).slice(-2)
    : '';
};
export const isMaskedPhoneNumber = (phone: string | undefined | null | number) => {
  return /(\+1\s)?\(\*{3}\)\s\*{3}\s\*{2}\d\d/.test(phone as string)
}

export const maskEmail = (email: string) => {
  return email !== ''
    ? email.toString().slice(0, 2) +
    '******' +
    email.toString().split('@')[0].slice(-2) +
    '@' +
    email.toString().split('@')[1]
    : '';
};

export const isValidEmail = (email: string): boolean => {
  return Boolean(EMAIL_REGEX.test(email));
};

export const isValidLogin = (login: string): boolean => {
  return isValidEmail(login) || isValidPhoneNumber(login) || isValidDecodedUuid(login) || isValidUUID(login)
};

export const isValidDecodedUuid = (encodedUUID: string): boolean => {
  try {
    return isValidUUID(atob(encodedUUID))
  } catch (e) {
    return false;
  }
}
export const isValidUUID = (uuid: string): boolean => UUID_REGEX.test(uuid)

// frontend/src/components/FindPeopleList/FindPeopleList.tsx
// frontend/src/components/FindPeopleSearchForm/FindPeopleSearchForm.tsx
export const isValidPhoneNumber = (phone: any): boolean => {
  const phoneNumber = isBlank(phone) ? '' : phone.toString();
  return PHONE_REGEX.test(phoneNumber);
};

export const hasLowerCase = (str: string) => str.toUpperCase() !== str;
export const hasUpperCase = (str: string) => str.toLowerCase() !== str;
export const hasNumber = (str: string) => /\d/.test(str);
export const min = (str: string, minLength: number) => str.length >= minLength;
export const max = (str: string, minLength: number) => str.length < minLength;

export const landingPage = (hasPendingActivities) =>
  hasPendingActivities ? '/app/activity' : '/app/';

export const htmlDecode = (input) => {
  var doc = new DOMParser().parseFromString(input, 'text/html');
  return doc.documentElement.textContent;
};

export const formatCurrency = (value: any) => {
  if (value === null || value === undefined) return value;
  let _value = value;

  if (value && !Number.isInteger(value)) {
    //safety to string cast
    _value = Number(value.toString().replace('$', '').replace(',', ''));
  }

  return Intl.NumberFormat('en-CA', {
    style: 'currency',
    currency: 'CAD',
  }).format(Number(_value));
};

export const currencyStringToNumber = (amount: string | null) =>
  isBlank(amount) ? 0 : toDecimal(amount?.toString());

export const isRNAPP = () => {
  return (window as any).isRNAPP;
};

export const reactNativeWebView = () => {
  return (window as any).ReactNativeWebView;
};

export const reactNativeWebViewMessage = (message) => {
  return (window as any).ReactNativeWebView.postMessage(message);
};

export const sendWebWrapperMessage = (payload) => {
  if (isRNAPP() && reactNativeWebView()) {
    reactNativeWebViewMessage(JSON.stringify(payload));
    segmentTrack('WebWrapper', 'Message', payload, 'sent');
  }
};

export const truncate = (str: string, length: number): string => {
  if (!str) return "";
  if (str.length > length) {
    return str.substring(0, length) + '...';
  }
  return str;
};

export const capitalizeFirstLetter = (
  value: any,
  locale = navigator.language
) => {
  const [first, ...rest] = value;
  return first.toLocaleUpperCase(locale) + rest.join('');
};

export const userTimezoneOffset = (dt) => dt.getTimezoneOffset() * 60000;

export const dateWithTimezoneOffset = (dt) =>
  new Date(dt.getTime() + userTimezoneOffset(dt));

export const getMobileVersion = (userAgent) => {
  if (reactNativeWebView() && userAgent.includes('WebViewApp')) {
    return userAgent
      .slice(userAgent.indexOf('WebViewApp') + 'WebViewApp '.length)
      .split(' ')[0];
  }
};

export const publicAssetPath = (path) =>
  `${process.env.NODE_ENV != 'development' ? '/app' : ''}${path}`;

export const userAgent = window.navigator.userAgent;

export const cleanMobileVersion = () =>
  semver.coerce(getMobileVersion(userAgent).split('/').pop());

export const isMobileVersionGreaterThan = (version): boolean =>
  semver.gte(cleanMobileVersion(), version);

export const isValidURL = (value: string, localhost: boolean = false): boolean => {
  if (!localhost) return value.match(URL_REGEX) !== null;
  return value.match(URL_REGEX) !== null || value.match(URL_LOCALHOST) !== null;
};

export const kebabize = (str: string) => str.replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? "-" : "") + $.toLowerCase())

export const phoneMask = (e: React.FormEvent<HTMLInputElement>) => {
  // Phone number formated total size to use as limit
  e.currentTarget.maxLength = 18;

  let value = e.currentTarget.value;

  // REMOVE NON-DIGIT caracters to ap
  value = value.replace(/\D/g, "");

  // REMOVE 1 from fist DIGIT
  value = value.replace(/^1/, "");

  // APPLY MASK FOR for first 3 characters: `( 234` 
  if (value.length <= 3) {
    value = value.replace(/(\d)/, "( $1");

    // APPLY MASK FOR for 4 to 6 characters: `( 234 ) 345` 
  } else if (value.length > 3 && value.length <= 6) {
    value = value.replace(/(\d{3})(\d)/, "( $1 ) $2");

    // APPLY MASK FOR for complete Phone Number 6 to complete number: ( 234 ) 456 - 3454
  } else if (value.length > 6) {
    value = value.replace(/(\d{3})(\d{3})(\d)/, "( $1 ) $2 - $3");
  }

  // Store value in the target
  e.currentTarget.value = value;
  return e
}

export const recursiveToCamel = item => {
  if (Array.isArray(item)) {
    return item.map(el => recursiveToCamel(el));
  } else if (typeof item === 'function' || item !== Object(item)) {
    return item;
  }
  return Object.fromEntries(
    Object.entries(item).map(([key, value]) => [
      key.replace(/([-_][a-z])/gi, c => c.toUpperCase().replace(/[-_]/g, '')),
      recursiveToCamel(value),
    ]),
  );
};
export const toTitleCase = (s) =>
  s.replace(/^_*(.)|_+(.)/g, (s, c, d) => c ? c.toUpperCase() : ' ' + d.toUpperCase())

export const toSnakeCase = (str: string) => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const deepFlattenToObject = (obj, prefix = '') => {
  return Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? prefix + '_' : '';
    if (typeof obj[k] === 'object' && obj[k] !== null) {
      Object.assign(acc, deepFlattenToObject(obj[k], pre + k));
    } else {
      acc[pre + k] = obj[k];
    }
    return acc;
  }, {});
}

export const extractKeys = (raw: any, keys: string[] = []) => {
  return Object.keys(raw)
    .filter(key => !keys.includes(key))
    .reduce((obj, key) => {
      obj[key] = raw[key];
      return obj;
    }, {});
}
