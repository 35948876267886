import { setCurrentUser } from 'lib/api';
import { useRef, useCallback } from 'react';
import { useMeQuery, User } from 'types';
import { isRNAPP, sendWebWrapperMessage } from 'lib/utils';
import Favicon from 'components/Favicon';
import { makeVar, useReactiveVar } from '@apollo/client';
import { segmentIdentify } from 'lib/Segment';
import { isAuthed, getToken, clearTokens } from 'lib/vertoStore';

// currentUser needs to be a reactive variable so that it can be updated
const currentUserVar = makeVar<User | null>(null);
const loadingVar = makeVar<boolean>(false);
const errorVar = makeVar<Error | null>(null);

// This should be the central chokepoint
export const useCurrentUser = () => {

  const updateBadge = useCallback((currentUser) => {
    const { badgeCount } = currentUser;
    if (badgeCount > 0) {
      Favicon(badgeCount);
      isRNAPP() &&
        sendWebWrapperMessage({
          'badge-number': badgeCount,
        });
    } else {
      Favicon(0);
    }
  }, []);

  // on load, grab the current user from the server
  const { data, loading, error, refetch, client } = useMeQuery({
    // if there's no token, don't be making a spurious request
    skip: !isAuthed(),
    onCompleted(data: { me: User }) {
      updateBadge(data.me || {});
      // NOTE: Release Green Screen in the Mobile App
      sendWebWrapperMessage({ 'auth-pageLoaded': 'true' });
    },
    onError(data) {
      updateBadge({});
      // NOTE: Release Green Screen in the Mobile App
      sendWebWrapperMessage({ 'auth-pageLoaded': 'true' });
    },
    notifyOnNetworkStatusChange: true,
  });

  // update the currentUser reactive variable if it changed
  if (data?.me !== currentUserVar()) {
    currentUserVar(data?.me);
    if (data?.me) {
      segmentIdentify(data?.me);
    }    
  }
  if (loading !== loadingVar()) {
    loadingVar(loading);
  }
  // update the error reactive variable if it changed
  if (error !== errorVar()) {
    errorVar(error);
  }  

  // update the badge count when the component re-renders
  updateBadge(data?.me || {});

  // watch localstorage for any changes to the USER_VERTO_TOKEN and react when it changes
  const authToken = useRef<null | string>(getToken()); // Make this a useRef
  window.addEventListener('storage', () => {
    if (authToken.current !== getToken()) {
      authToken.current = getToken();
      if (authToken.current) {
        // we got a new token
        refetch();
      } else {
        // clear the cached object for Me, so everyone using the meQuery gets the notice.
        clearUser();
      }
    }
  });

  const clearUser = () => {
    // first, remove the tokens from localstorage so no query can be refetched
    clearTokens();
    // now, update the me query to be null. All observers will be notified of this change
    setCurrentUser(null);
    // finally, garbage collect the cache to remove the User entry along with any other dangling references
    client.cache.gc();
  };

  // TODO: add more useful session and current user management here...
  const logout = () => {
    // clear the currentUser from the cache
    clearUser();
  };

  // const generateOTP = () => {
  //   if (currentUser) return;
  // };
  //
  // const login = ({phone, pass, otp, redirectPathIntent}) => {
  //   // call sign in mutation with phone and pass
  //   // if the otp was passed, call the sign in with phone and otp
  //   // upon succssful sign in, redirect to redirectPath
  // };
  //
  // const claimInvited = () => {};
  //
  // const updateProfile = (props: UserType) => {
  //   // set loading
  //   // send mutation to server
  //   // set errors if any
  // };
  //
  // const signUp = ({}) => {
  //
  // }

  return {
    currentUser: useReactiveVar(currentUserVar), // use the query result istead of have a extra state
    loading: useReactiveVar(loadingVar),
    error: useReactiveVar(errorVar),
    reloadCurrentUser: refetch, // we just need the refetch don't need to call the lazy query
    logout,
    // TODO: in future for login and others
    // login,
    // ...
  };
};
