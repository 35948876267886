import { gql } from '@apollo/client';
export const ORGANIZATION_FRAGMENT = gql`
  fragment organizationFields on Organization {
    guid
    name
    autoRedirect
    directCharge
    logoUrl
    publishableKeys {
      type
      key
      status
      expiresAt
    }
    secretKeys {
      type
      key
      status
      expiresAt
    }
    webhookEndpoints {
      guid
      livemode
      status
      description
      url
      enabledEvents
    }
  }
`;
