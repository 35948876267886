import { gql } from '@apollo/client';
export const PAYMENT_ITEM_FRAGMENT = gql`
  fragment paymentItemFields on PaymentItem {
    ...reactionFields
    amount
    createdAt
    description
    displayDateTime
    expiry
    expiryInDays
    remindedAt
    scope
    smsMessage
    splitGroupUserUuid
    splitGroupUuid
    status
    type
    updatedAt
    uuid
    ... on ScheduledTask {
      repeatFrequency
      endDate
      endCount
      scheduledAt
      paymentType
    }
    paymentTemplate {
      ...paymentTemplateMinimalFields
    }
    debitor {
      ...userDetailFields
    }
    creditor {
      ...userDetailFields
    }
    paymentMethod {
      ...paymentMethodMinimalFields
    }
  }
`;
