import { useNavigate, useLocation } from 'react-router-dom';
import { isBlank } from 'lib/utils';
import queryString from 'query-string';
import { useCurrentUser } from './useCurrentUser';
import { useEffect } from 'react';
import { isAuthed } from 'lib/vertoStore';

export const INVITED_PAY = 'VERTO_INVITED_PAY';
export const INVITED_REQUEST = 'VERTO_INVITED_REQUEST';
export const PRE_REGISTER = 'VERTO_PRE_REGISTERED';
export const DEEP_LINK = 'DEEP_LINK_PAYMENT_ITEM_ID';
export const PAY_LINK = 'VERTO_INVITED_PAY_LINK';

export const cleanStorage = () => {
  storageRemove(DEEP_LINK);
  storageRemove(INVITED_PAY);
  storageRemove(INVITED_REQUEST);
  storageRemove(PRE_REGISTER);
  storageRemove(PAY_LINK);
}


export const storageGet = (key: string) => sessionStorage.getItem(key);
export const storageRemove = (key: string) => sessionStorage.removeItem(key);
export const storageSet = (key: string, value: any) => {
  if (key !== 'VERTO_NEW_USER')
    cleanStorage();
  sessionStorage.setItem(key, value);
}

export const existVertoSessions = () => {
  return !isBlank(storageGet(DEEP_LINK)) ||
    !isBlank(storageGet(INVITED_PAY)) ||
    !isBlank(storageGet(INVITED_REQUEST)) ||
    !isBlank(storageGet(PRE_REGISTER)) ||
    !isBlank(storageGet(PAY_LINK))
}

export const storageParse = (key: string) =>
  isBlank(storageGet(key)) ? '' : JSON.parse(storageGet(key) || '{}');


export const useRedirections = () => {
  const { currentUser } = useCurrentUser()
  const navigate = useNavigate();
  const location = useLocation();
  const state = location?.state
  const missingInfo = currentUser?.missingInfo
  const profileComplete = missingInfo && (!missingInfo.includes('name') && !missingInfo.includes('vertoTag'))

  const redirectBackToStart = () => {
    if (isAuthed() && currentUser && !profileComplete && !location.pathname.match('get-started') && !location.pathname.match('logout')) {
      navigate("/app/get-started", { state })
    }
  }

  const redirectToFrom = () => {
    if (isAuthed() && currentUser && state?.from) navigate(state?.from);
  }

  const setDeepLink = () => {
    if (!isAuthed()) {
      const pathname = location?.state?.from?.pathname ?? location.pathname ?? '';
      if (
        pathname.includes('payment-item') ||
        pathname.includes('payment-confirmation')
      ) {
        const uuid = pathname.split('/').pop();
        storageSet(DEEP_LINK, uuid);

        if (pathname.includes('payment-confirmation')) {
          navigate(`/app/payment-item/${uuid}`);
        }
      }
    }
  }

  const redirectPayLink = () => {
    const payLink: any = storageParse(PAY_LINK);
    if (isAuthed() && !isBlank(payLink) && profileComplete) {
      if (payLink.paymentVariables) {
        storageRemove(PAY_LINK);
        const uuid = payLink.paymentVariables.uuid
        navigate(`/app/pay/link/${uuid}`, {
          state: { ...payLink }
        });
      }
    }
  };;

  const redirectDeepLink = () => {
    const deepLinkUuid: any = storageGet(DEEP_LINK);
    if (isAuthed() && !isBlank(deepLinkUuid)) {
      storageRemove(DEEP_LINK);
      navigate(`/app/payment-item/${deepLinkUuid}`, {
        state: { isDeepLink: true }
      });
    }
  };

  const redirectPreRegister = () => {
    const preRegistered: any = storageParse(PRE_REGISTER);

    if (isAuthed() && !isBlank(preRegistered)) {
      const {
        action,
        transaction: {
          user: { uuid },
          amount,
          note,
        },
      } = preRegistered;
      storageRemove(PRE_REGISTER);
      if (action) {
        const path = `/${action.toLowerCase()}/${uuid ? uuid : ''}`;
        const params = queryString.stringify({ note, amount });
        navigate(`/app${path}${isBlank(params) ? '' : `?${params}`}`);
      }
    }
  };

  const redirectInvitedRequest = () => {
    const invitedReq: any = storageParse(INVITED_REQUEST);
    if (isBlank(invitedReq) || invitedReq?.status === 'invited') return;
    const {
      payOrRequestResponse: { request: transferItem },
    } = invitedReq;
    if (profileComplete) storageRemove(INVITED_REQUEST)
    if (profileComplete && transferItem) {
      const state = {
        origin: 'PaymentRequest',
        paymentRequestUuid: transferItem.uuid,
        userSelected: transferItem.creditor,
        amount: transferItem.amount,
        note: transferItem.description,
      }
      navigate("/app/pay", { state })
    }
  };

  const redirectInvitedPay = () => {
    const invitedPay: any = storageParse(INVITED_PAY);
    if (isBlank(invitedPay) || invitedPay?.status === 'invited') return;

    const {
      payOrRequestResponse: {
        status,
        // payment: transferItem,
        payment: { uuid },
        // response: error,
      },
    } = invitedPay;
    if (profileComplete) storageRemove(INVITED_PAY)

    if (profileComplete && status && status !== 'invited') {
      navigate(`/app/payment-item/${uuid}`);
    }
  };

  useEffect(() => {
    redirectBackToStart();
    redirectToFrom();
    setDeepLink();
    redirectDeepLink();
    redirectPayLink();
    redirectPreRegister();
    redirectInvitedRequest();
    redirectInvitedPay();
  }, [navigate, location, currentUser])
};
