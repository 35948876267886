import { gql } from '@apollo/client';
export const PAYMENT_METHOD_FRAGMENT = gql`
  fragment paymentMethodFields on PaymentMethod {
    ...paymentMethodMinimalFields
    accountBalance
    accountBalanceRefreshed
    business
    createdAt
    stripeId
    type
    uuid
    vendor
  }
`;
;
