import { gql } from '@apollo/client';
export const CURRENT_USER_FRAGMENT = gql`
  fragment currentUserFields on User {
    ...currentUserMinimalFields
    bankTransferAmountLength
    channelName
    hasIncompleteProfile
    hasPendingActivities
    incompleteProfileLength
    isImpersonated
    paymentAmountDailyMaximum
    paymentAmountLength
    pendingActivitiesLength
    settings
    url
    missingInfo
    unverifiedEmail
    badgeCount
    paymentMethods {
      ...paymentMethodFields
    }
    memberships {
      ...membershipFields
    }
  }
`;
