import plainIcon from 'assets/img/favicon.ico';
import badgeIcon from 'assets/img/favicon-badge.ico';

const getLink = (rel) => {
  return (
    document.querySelector(`link[rel="${rel}"]`) || document.createElement(rel)
  );
};

const updateLink = (rel, path) => {
  getLink(rel).href = path;
};

const Favicon = (badgeNumber = 0) => {
  updateLink(
    'icon',
    badgeNumber > 0 ? badgeIcon : plainIcon
  );
  if (badgeNumber > 0)
    document.title = `[${badgeNumber}] Verto™`;
  else document.title = `Verto™`;

  return null;
};

export default Favicon;
