
import { gql } from '@apollo/client';
export const CURRENT_USER_MINIMAL_FRAGMENT = gql`
  fragment currentUserMinimalFields on User {
    accountBalance
    auth0Id
    createdAt
    email
    firstName
    lastName
    name
    phone
    profileImage
    status
    updatedAt
    username
    uuid
  }
`;
